.chr-ciphr-light {
  --chr-action-surface-success-hover: #c1d7b3ff;
  --chr-interface-stroke-informative: #ebe5fbff;
  --chr-interface-stroke-success: #e1ecdaff;
  --chr-interface-stroke-informative-inverse: #6c50bfff;
  --chr-interface-stroke-success-inverse: #377114ff;
  --chr-interface-content-data07: #5c75b4ff;
  --chr-action-stroke-informative-idle: #d5caf6ff;
  --chr-action-stroke-success-idle: #c1d7b3ff;
  --chr-action-surface-success-active: #a1c38cff;
  --chr-action-surface-success-inverse-idle: #428718ff;
  --chr-interface-surface-data04: #f8edf5ff;
  --chr-interface-surface-data05: #ebf1fcff;
  --chr-interface-surface-data08: #f1f2ebff;
  --chr-interface-content-inverse: #ffffffff;
  --chr-interface-surface-success-inverse: #428718ff;
  --chr-interface-surface-informative: #f2effcff;
  --chr-interface-surface-success: #ecf3e8ff;
  --chr-interface-surface-informative-inverse: #815fe3ff;
  --chr-interface-surface-warning: #faede6ff;
  --chr-interface-content-warning: #cd4d05ff;
  --chr-interface-surface-data01: #eaf2f4ff;
  --chr-action-surface-informative-inverse-hover: #6c50bfff;
  --chr-action-surface-informative-inverse-active: #58419aff;
  --chr-action-stroke-warning-hover: #e6a682ff;
  --chr-interface-stroke-warning: #f7e3d7ff;
  --chr-interface-stroke-warning-inverse: #ac4104ff;
  --chr-interface-content-data03: #af6323ff;
  --chr-interface-content-data04: #bd4b96ff;
  --chr-action-surface-informative-inverse-idle: #815fe3ff;
  --chr-action-surface-informative-active: #c0aff1ff;
  --chr-action-surface-success-inverse-active: #2d5c10ff;
  --chr-interface-content-success: #428718ff;
  --chr-interface-content-data08: #6f7b3bff;
  --chr-action-surface-informative-idle: #ebe5fbff;
  --chr-interface-surface-data02: #f1effdff;
  --chr-interface-content-informative: #815fe3ff;
  --chr-action-surface-informative-hover: #d5caf6ff;
  --chr-interface-content-data05: #3970e2ff;
  --chr-action-surface-success-idle: #e1ecdaff;
  --chr-action-surface-success-inverse-hover: #377114ff;
  --chr-interface-content-data06: #af5978ff;
  --chr-interface-surface-data03: #f7efe9ff;
  --chr-interface-surface-data06: #f7eef2ff;
  --chr-interface-surface-data07: #eff1f8ff;
  --chr-interface-content-data01: #2d7f90ff;
  --chr-interface-content-data02: #7360eeff;
  --chr-action-content-informative-idle: #6c50bfff;
  --chr-action-content-success-active: #21440cff;
  --chr-action-content-informative-active: #413072ff;
  --chr-action-content-success-idle: #377114ff;
  --chr-action-content-success-hover: #2d5c10ff;
  --chr-action-content-informative-hover: #58419aff;
  --chr-action-stroke-success-hover: #a1c38cff;
  --chr-action-stroke-informative-hover: #c0aff1ff;
  --chr-action-stroke-success-active: #6da24eff;
  --chr-action-stroke-informative-active: #9c81e9ff;
  --chr-action-content-warning-active: #672703ff;
  --chr-action-surface-warning-idle: #f7e3d7ff;
  --chr-action-surface-warning-active: #e6a682ff;
  --chr-action-content-warning-hover: #8b3403ff;
  --chr-action-surface-warning-inverse-idle: #cd4d05ff;
  --chr-action-surface-warning-inverse-hover: #ac4104ff;
  --chr-action-surface-warning-hover: #efc4adff;
  --chr-action-stroke-warning-idle: #efc4adff;
  --chr-action-content-warning-idle: #ac4104ff;
  --chr-action-surface-warning-inverse-active: #8b3403ff;
  --chr-action-stroke-warning-active: #d97841ff;
  --chr-interface-surface-warning-inverse: #cd4d05ff;
  --chr-action-content-neutral-inverse: #ffffffff;
  --chr-action-content-warning-inverse: #ffffffff;
  --chr-action-content-success-inverse: #ffffffff;
  --chr-action-content-accent-inverse: #ffffffff;
  --chr-action-content-inverse: #ffffffff;
  --chr-action-content-informative-inverse: #ffffffff;
  --chr-interface-content-informative-bold: #58419aff;
  --chr-interface-content-warning-bold: #8b3403ff;
  --chr-input-stroke-success: #428718ff;
  --chr-interface-content-success-bold: #2d5c10ff;
  --chr-input-stroke-informative: #815fe3ff;
  --chr-interface-content-success-bolder: #162e08ff;
  --chr-input-surface: #ffffffff;
  --chr-input-stroke-warning: #cd4d05ff;
  --chr-input-stroke-error: #dc3a24ff;
  --chr-interface-content-informative-bolder: #2c204dff;
  --chr-interface-content-warning-bolder: #461a02ff;
  --chr-interface-surface: #ffffffff;
  --chr-action-surface: #ffffffff;
  --chr-interface-stroke-inverse: #ffffffff;
  --chr-interface-stroke-data07: #e5e9f3ff;
  --chr-interface-stroke-data04: #f4e2eeff;
  --chr-interface-stroke-data03: #f2e6dcff;
  --chr-interface-stroke-data05: #dfe8faff;
  --chr-interface-stroke-data08: #e8eae0ff;
  --chr-interface-stroke-data02: #e9e6fcff;
  --chr-interface-stroke-data01: #ddebedff;
  --chr-interface-stroke-data06: #f2e4e9ff;
  --chr-action-surface-error-inverse-hover: #b9311eff;
  --chr-action-surface-error-hover: #f3beb7ff;
  --chr-interface-content-error: #dc3a24ff;
  --chr-action-stroke-error-hover: #ee9d92ff;
  --chr-interface-surface-error: #fcebe9ff;
  --chr-interface-stroke-error: #f9dfdcff;
  --chr-action-content-error-idle: #b9311eff;
  --chr-interface-surface-error-inverse: #dc3a24ff;
  --chr-interface-stroke-error-inverse: #b9311eff;
  --chr-interface-content-error-bolder: #4b140cff;
  --chr-interface-content-error-bold: #962718ff;
  --chr-action-content-error-hover: #962718ff;
  --chr-action-content-error-active: #6e1d12ff;
  --chr-action-surface-error-inverse-active: #962718ff;
  --chr-action-stroke-error-active: #e46959ff;
  --chr-action-surface-error-idle: #f9dfdcff;
  --chr-action-surface-error-active: #ee9d92ff;
  --chr-action-content-error-inverse: #ffffffff;
  --chr-action-stroke-error-idle: #f3beb7ff;
  --chr-interface-link-active: #005487ff;
  --chr-interface-overlay-gradient-right: linear-gradient(90deg, #ffffff00 0%, #ffffffff 100%);
  --chr-interface-overlay-gradient-top: linear-gradient(0deg, #ffffff00 0%, #ffffffff 100%);
  --chr-interface-overlay-gradient-bottom: linear-gradient(0deg, #ffffffff 0%, #ffffff00 100%);
  --chr-interface-overlay-gradient-left: linear-gradient(90deg, #ffffffff 0%, #ffffff00 100%);
  --chr-interface-content-data08-bold: #4b5428ff;
  --chr-interface-content-data08-bolder: #262a14ff;
  --chr-interface-stroke-data08-inverse: #5d6732ff;
  --chr-interface-content-data05-bold: #274c9aff;
  --chr-interface-content-data03-bolder: #3c220cff;
  --chr-interface-content-data03-bold: #774318ff;
  --chr-interface-content-data04-bolder: #401a33ff;
  --chr-interface-content-data04-bold: #813366ff;
  --chr-interface-content-data05-bolder: #13264dff;
  --chr-interface-surface-data01-inverse: #2d7f90ff;
  --chr-interface-content-data07-bold: #3f507aff;
  --chr-interface-content-data07-bolder: #1f283dff;
  --chr-interface-stroke-data07-inverse: #4d6297ff;
  --chr-interface-stroke-data05-inverse: #305ebeff;
  --chr-interface-content-data06-bold: #773d52ff;
  --chr-interface-content-data01-bold: #1f5662ff;
  --chr-interface-content-data02-bolder: #272151ff;
  --chr-interface-content-data01-bolder: #0f2b31ff;
  --chr-interface-stroke-data04-inverse: #9f3f7eff;
  --chr-interface-surface-data02-inverse: #7360eeff;
  --chr-interface-surface-data03-inverse: #af6323ff;
  --chr-interface-stroke-data03-inverse: #93531dff;
  --chr-interface-stroke-data02-inverse: #6151c8ff;
  --chr-interface-surface-data06-inverse: #af5978ff;
  --chr-interface-stroke-data01-inverse: #266b79ff;
  --chr-interface-surface-data07-inverse: #5c75b4ff;
  --chr-interface-content-data06-bolder: #3c1e29ff;
  --chr-interface-surface-data05-inverse: #3970e2ff;
  --chr-interface-content-data02-bold: #4e41a2ff;
  --chr-interface-surface-data08-inverse: #6f7b3bff;
  --chr-interface-surface-data04-inverse: #bd4b96ff;
  --chr-interface-stroke-data06-inverse: #934b65ff;
  --chr-interface-overlay-gradient-top-bold: linear-gradient(0deg, #ffffff00 0%, #ffffffff 38.54166567325592%, #ffffffff 100%);
  --chr-interface-overlay-gradient-left-bold: linear-gradient(90deg, #ffffffff 0%, #ffffffff 58.85416865348816%, #ffffff00 100%);
  --chr-interface-overlay-gradient-right-bold: linear-gradient(90deg, #ffffff00 0%, #ffffffff 33.85416567325592%);
  --chr-interface-overlay-gradient-bottom-bold: linear-gradient(0deg, #ffffffff 0%, #ffffffff 58.33333134651184%, #ffffff00 100%);
  --chr-interface-overlay-inverse: #ffffffb8;
  --chr-interface-content-accent-inverse: #ffffffff;
  --chr-interface-content-error-inverse: #ffffffff;
  --chr-interface-content-neutral-inverse: #ffffffff;
  --chr-interface-content-success-inverse: #ffffffff;
  --chr-interface-content-warning-inverse: #ffffffff;
  --chr-interface-content-informative-inverse: #ffffffff;
  --chr-primitive-black: #000000ff;
  --chr-primitive-white: #ffffffff;
  --chr-primitive-black-alpha: #0000000f;
  --chr-primitive-white-alpha: #ffffff14;
  --chr-primitive-neutral-0: #ffffffff;
  --chr-action-surface-accent-inverse-hover: #003d52ff;
  --chr-interface-surface-bold: #fbfbfbff;
  --chr-interface-surface-accent-inverse-bold: #003142ff;
  --chr-action-surface-accent-inverse-active: #003142ff;
  --chr-primitive-accent-300: #b6f1ebff;
  --chr-primitive-neutral-1100: #292929ff;
  --chr-primitive-neutral-400: #bfbfbfff;
  --chr-primitive-neutral-500: #949494ff;
  --chr-action-surface-accent-active: #5ec0c9ff;
  --chr-interface-content-neutral-bold: #525252ff;
  --chr-interface-surface-accent-bolder: #b6f1ebff;
  --chr-action-surface-error-inverse-idle: #dc3924ff;
  --chr-primitive-accent-500: #1fa1adff;
  --chr-primitive-neutral-800: #666666ff;
  --chr-action-surface-neutral-active: #bfbfbfff;
  --chr-primitive-neutral-1000: #383838ff;
  --chr-action-stroke-neutral-idle: #d9d9d9ff;
  --chr-primitive-neutral-900: #525252ff;
  --chr-action-stroke-neutral-active: #949494ff;
  --chr-interface-surface-accent-inverse: #004c66ff;
  --chr-interface-content-accent-bolder: #001f2eff;
  --chr-interface-surface-neutral-bolder: #d9d9d9ff;
  --chr-action-surface-neutral-inverse-idle: #737373ff;
  --chr-action-surface-accent-inverse-idle: #004c66ff;
  --chr-action-content-neutral-hover: #525252ff;
  --chr-action-stroke-accent-idle: #b6f1ebff;
  --chr-primitive-accent-800: #003d52ff;
  --chr-primitive-accent-1200: #001824ff;
  --chr-interface-overlay-neutral: #292929b8;
  --chr-interface-surface-neutral-inverse-bolder: #292929ff;
  --chr-action-surface-neutral-hover: #d9d9d9ff;
  --chr-primitive-accent-100: #e2f9f7ff;
  --chr-action-stroke-accent-hover: #5ec0c9ff;
  --chr-input-stroke-active: #004c66ff;
  --chr-primitive-accent-50: #f8fefdff;
  --chr-interface-surface-neutral: #f1f1f1ff;
  --chr-primitive-neutral-100: #f1f1f1ff;
  --chr-primitive-neutral-600: #8c8c8cff;
  --chr-interface-content-accent-bold: #003142ff;
  --chr-input-surface-active: #004c66ff;
  --chr-input-surface-bold: #fbfbfbff;
  --chr-primitive-neutral-1200: #1f1f1fff;
  --chr-primitive-neutral-200: #e6e6e6ff;
  --chr-primitive-neutral-50: #fbfbfbff;
  --chr-interface-surface-neutral-bold: #e6e6e6ff;
  --chr-interface-stroke-neutral: #e6e6e6ff;
  --chr-interface-surface-neutral-inverse-bold: #525252ff;
  --chr-primitive-accent-1100: #001f2eff;
  --chr-interface-content-accent: #004c66ff;
  --chr-interface-content-neutral: #737373ff;
  --chr-interface-surface-accent-inverse-bolder: #001f2eff;
  --chr-primitive-accent-1300: #000e14ff;
  --chr-action-surface-accent-hover: #b6f1ebff;
  --chr-action-content-neutral-idle: #666666ff;
  --chr-action-content-neutral-active: #383838ff;
  --chr-primitive-accent-900: #003142ff;
  --chr-action-stroke-accent-active: #1fa1adff;
  --chr-action-surface-neutral-idle: #e6e6e6ff;
  --chr-action-content-accent-idle: #003d52ff;
  --chr-primitive-neutral-700: #737373ff;
  --chr-action-content-accent-active: #002838ff;
  --chr-action-surface-accent-idle: #ccf5f1ff;
  --chr-action-surface-neutral-inverse-hover: #666666ff;
  --chr-primitive-accent-200: #ccf5f1ff;
  --chr-primitive-accent-600: #0f6b8aff;
  --chr-interface-content-neutral-bolder: #292929ff;
  --chr-input-surface-bolder: #949494ff;
  --chr-primitive-accent-1000: #002838ff;
  --chr-interface-surface-accent-bold: #ccf5f1ff;
  --chr-interface-stroke-neutral-inverse: #666666ff;
  --chr-primitive-accent-700: #004c66ff;
  --chr-action-content-accent-hover: #003142ff;
  --chr-primitive-accent-400: #5ec0c9ff;
  --chr-primitive-neutral-1300: #141414ff;
  --chr-interface-stroke-accent: #ccf5f1ff;
  --chr-input-stroke-neutral: #949494ff;
  --chr-interface-surface-accent: #e2f9f7ff;
  --chr-interface-surface-neutral-inverse: #737373ff;
  --chr-primitive-neutral-300: #d9d9d9ff;
  --chr-action-stroke-neutral-hover: #bfbfbfff;
  --chr-action-surface-neutral-inverse-active: #525252ff;
  --chr-interface-link: #0f6b8aff;
  --chr-action-surface-accent-inverse-bold-idle: #003142ff;
  --chr-action-surface-accent-inverse-bolder-active: #000e14ff;
  --chr-action-surface-accent-inverse-bolder-idle: #001f2eff;
  --chr-action-surface-accent-inverse-bolder-hover: #001824ff;
  --chr-action-surface-accent-inverse-bold-active: #001f2eff;
  --chr-action-surface-accent-inverse-bold-hover: #002838ff;
  --chr-action-surface-accent-bold-active: #0f6b8aff;
  --chr-action-surface-accent-bold-hover: #1fa1adff;
  --chr-action-surface-accent-bold-idle: #5ec0c9ff;
  --chr-interface-stroke-accent-inverse: #004c66ff;
  --chr-action-content-accent: #ffffffff;
  --chr-body-m-bold: 800 14px/23px Manrope;
  --chr-body-m-light: 500 14px/21px Manrope;
  --chr-body-m: 600 14px/23px Manrope;
  --chr-body-s: 600 12px/20px Manrope;
  --chr-detail-xxl-bold: 800 16px/18px Manrope;
  --chr-body-xxl-bold: 800 20px/29px Manrope;
  --chr-title-l: 800 24px/33px Manrope;
  --chr-title-s: 800 18px/24px Manrope;
  --chr-title-xs: 800 16px/22px Manrope;
  --chr-body-xxl: 500 20px/29px Manrope;
  --chr-detail-l-bold: 800 12px/14px Manrope;
  --chr-detail-xxl-light: 500 16px/18px Manrope;
  --chr-title-xxl: 800 36px/46px Manrope;
  --chr-body-l-bold: 800 16px/25px Manrope;
  --chr-body-l: 500 16px/25px Manrope;
  --chr-body-xl-bold: 800 18px/27px Manrope;
  --chr-title-xl: 800 28px/37px Manrope;
  --chr-body-s-bold: 800 12px/20px Manrope;
  --chr-detail-l-light: 500 12px/14px Manrope;
  --chr-body-l-light: 400 16px/23px Manrope;
  --chr-body-xl: 500 18px/27px Manrope;
  --chr-body-xl-light: 400 18px/25px Manrope;
  --chr-title-m: 800 20px/27px Manrope;
  --chr-detail-xl-light: 500 14px/16px Manrope;
  --chr-detail-l: 600 12px/14px Manrope;
  --chr-detail-xl-bold: 800 14px/16px Manrope;
  --chr-link-s: 700 14px/16px Manrope;
  --chr-link-s-hover: 700 14px/16px Manrope;
  --chr-link-m: 700 16px/18px Manrope;
  --chr-link-m-hover: 700 16px/18px Manrope;
  --chr-detail-xl: 600 14px/16px Manrope;
  --chr-detail-xxl: 600 16px/18px Manrope;
  --chr-input-outline-success: 0px 0px 0px 3px #e1ecdaff;
  --chr-input-outline-warning: 0px 0px 0px 3px #f7e3d7ff;
  --chr-action-outline-focus: 0px 0px 0px 3px #815fe352;
  --chr-input-outline-error: 0px 0px 0px 3px #f9dfdcff;
  --chr-interface-shadow-xxl: 0px 25px 50px -16px #1f1f1f3d;
  --chr-input-outline-hover: 0px 0px 0px 3px #e6e6e6ff;
  --chr-interface-shadow-m: 0px 2px 4px -2px #1f1f1f14, 0px 4px 8px -1px #1f1f1f14;
  --chr-interface-shadow-xs: 0px 1px 2px 0px #1f1f1f0a, 0px 2px 4px 0px #1f1f1f0a;
  --chr-interface-shadow-l: 0px 4px 6px -4px #1f1f1f1a, 0px 10px 15px -3px #1f1f1f1a;
  --chr-interface-shadow-s: 0px 2px 5px -1px #1f1f1f0f, 0px 2px 5px 0px #1f1f1f0f;
  --chr-input-outline-focus: 0px 0px 0px 3px #004c6652;
  --chr-input-outline-active: 0px 0px 0px 3px #ccf5f1ff;
  --chr-interface-shadow-xl: 0px 8px 10px -6px #1f1f1f1a, 0px 20px 25px -5px #1f1f1f1a;
}
