@use "@angular/cdk" as cdk;
@use "@ciphr/styles" as ciphr;

@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "tippy.js/animations/scale.css";

@import "@ciphr-design-system/foundations/ciphr-light";
@import "@angular/cdk/overlay-prebuilt.css";

@include cdk.a11y-visually-hidden();
@include ciphr.tooltip();

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;

  background: var(--chr-interface-surface-bold, #f7f8f9);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.page-container {
  display: block;

  margin: 0 auto;
  padding: 0;

  max-width: 90rem;
  width: 100%;
}
