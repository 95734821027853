@mixin tooltip {
    .tippy-variation-tooltip {
        min-width: 4rem;

        .tippy-box[data-theme~=light] {
            color: var(--chr-interface-content-neutral-bold, #445162);
            font: var(--chr-detail-l-bold);
            box-shadow: var(--chr-interface-shadow-l);

            .tippy-content {
                min-height: 1.75rem;
                padding: 0.5rem 0.75rem;
                border-radius: 0.5rem;
                text-align: center;
                background: var(--chr-interface-surface, #FFF);
            }
        }
    }
}